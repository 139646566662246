import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './index.module.css';
import Logo from '../components/logo';

const HowToInstallPage = () => {
  const downloadLink = 'https://bit.ly/3ad1b8f';

  return (
    <Layout>
      <SEO title="How to Install"/>

      {/* Logo */}
      <Logo/>

      {/* HowToInstall */}
      <div
        className={'p-20 text-gray-300 w-full bg-gray-800 lg:p-10 rounded-lg'}
        id={'how-to-install-stocksolo'}>
        <h2>
          How to install StockSolo
        </h2>
        <div>
          StockSolo is an extension available for free on the Adobe Exchange –
          which is the official store for all Creative Cloud applications.
          <br/>
          <br/>
          To install StockSolo:
          <br/>
          <ol className={'list-disc py-2 mb-0'}>
            <li>Make sure you installed the Creative Cloud Desktop application.
              If you haven't installed it, <a
                href={'https://helpx.adobe.com/download-install/kb/creative-cloud-desktop-app-download.html'}>visit
                this page</a> to see how to install it.
            </li>
            <li>Login in the Creative Cloud Desktop application. <a
              href={'https://helpx.adobe.com/download-install/using/sign-in-activate-creative-cloud.html'}>See
              here how to do it.</a></li>
            <li>Go to the Adobe Exchange portal by clicking on this <a
              href={'https://exchange.adobe.com/creativecloud.details.103558.stocksolo.html'}>link</a>.</li>
            <li>On this page, click on the <b>“Install”</b> button.</li>
            <li>Wait a few seconds, you should receive a notification from the
              Creative Cloud Desktop application.
            </li>
            <li>Close and re-open InDesign, Illustrator and Photoshop.</li>
          </ol>
          {/*If you have issues with the installation, check this user guide below.*/}
        </div>

        <div>
          {/*<h3>Install the extension manually</h3>*/}
          <h4 className={'text-white'}>If you are having trouble installing the
            extension</h4>

          If you were unable to install the extension using the Creative Cloud
          desktop app, go through the following steps:

          <ol className={'list-disc py-2 mb-0'}>
            <li>Make sure that "File sync" is enabled in the Adobe Creative
              Cloud application.
            </li>
            <li>Follow the instructions in the <a
              href={'https://helpx.adobe.com/exchange/kb/troubleshoot-adobe-exchange.html'}>official
              Adobe troubleshooting page</a>.
            </li>
            <li>Or you can install the extension manually following the
              instructions below.
            </li>
          </ol>

          <h4 className={'text-white'}>Install or update StockSolo manually</h4>

          You can also install StockSolo manually:

          <ol className={'list-disc py-2 mb-0'}>
            <li>Download the Anastasiy’s Extension Manager from this link <a
              href={'http://install.anastasiy.com'}>http://install.anastasiy.com</a> and
              install it.
            </li>
            <li>Download the StockSolo extension from this link: <a
              href={downloadLink} className={'text-bold'}>Download</a>.</li>
            <li>Open the Anastasiy’s Extension Manager.</li>
            <li>Drag and Drop the <a href={downloadLink}>“StockSolo.zxp”</a> inside Anastasiy’s Extension
              Manager.
            </li>
            <li>Follow the installation steps.</li>
            <li>Go back to this page to see how to open Stock solo in InDesign,
              Illustrator and Photoshop.
            </li>
          </ol>

          <div className="mt-5 mb-10">
            <a href={downloadLink} className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 ' +
    'px-4 mt-5 lg:mt-0 lg:ml-2 w-full rounded focus:outline-none ' +
    'focus:shadow-outline lg:flex-2 text-center'}>
            Download "StockSolo.zxp"
          </a>
          </div>

          <i>
            Adobe Exchange: If you're not familiar with Adobe Exchange, it's the
            official Adobe extension directory. Every Adobe CC user has free
            access to it, and it currently contains more than 3,000 plugins.
          </i>
        </div>

        <h2 className={'pt-5'}>
          Open StockSolo in InDesign, Illustrator, and Photoshop
        </h2>
        <ol className={'list-disc mb-0'}>
          <li>Open InDesign, Illustrator or Photoshop.</li>
          <li>Go to <b>“Window”</b> > <b>“Extensions”</b> and click
            on <b>“StockSolo”</b>.
          </li>
        </ol>
      </div>

    </Layout>
  );
};

export default HowToInstallPage;
